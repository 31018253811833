
import { defineComponent } from 'vue';
import Navigation from '@/components/Navigation.vue';
import Profile from '@/assets/images/profile.jpg';
import LFZ from '@/assets/images/learning-fuze.png';
import SurePrep from '@/assets/images/sureprep.jpeg';

export default defineComponent({
  name: 'Home',
  components: {
    Navigation,
  },
  data() {
    return {
      profileImg: Profile,
      learningFuzeImg: LFZ,
      sureprepImg: SurePrep,
    };
  },
});
