
import { Paths } from '@/router/Paths';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Navigation',
  computed: {
    Paths() {
      return Paths;
    },
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
